<script>
export default {
  name: "Links",
};
</script>

<template>
  <section id="sectionLinks">
    <h2>Links</h2>
    <a href="https://www.instagram.com/luneriane_ffxiv/">
      <button>
        <img src="https://img.icons8.com/fluent/48/000000/instagram-new.png" />
        <span>Instagram</span>
      </button>
    </a>
    <a href="https://twitter.com/luneriane_ffxiv">
      <button>
        <img src="https://img.icons8.com/fluent/48/000000/twitter.png" />
        <span>Twitter</span>
      </button>
    </a>
    <a href="https://www.xivmodarchive.com/user/25492">
      <button>
        <img src="../assets/XMA_icon.png" />
        <span>XMA Website</span>
      </button>
    </a>
    <a href="https://discord.gg/q4GHHfSd2p">
      <button>
        <img src="https://img.icons8.com/fluent/48/000000/discord-logo.png" />
        <span>Discord</span>
      </button>
    </a>
    <a href="https://ko-fi.com/luneriane">
      <button>
        <img src="https://ko-fi.com/favicon.png" />
        <span>Ko-fi</span>
      </button>
    </a>
    <a href="https://www.patreon.com/luneriane">
      <button>
        <img
          src="https://c5.patreon.com/external/favicon/favicon-32x32.png?v=69kMELnXkB"
        />
        <span>Patreon</span>
      </button>
    </a>
  </section>
</template>

<style>
#sectionLinks button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10em;
  margin-top: 1em;
  padding: 0.5em 0.25em;
  border-radius: 1em;
  border: none;
  background-color: rgba(100, 148, 237, 0.75);
  color: lightgoldenrodyellow;
  text-shadow: 1px 1px 2px rgb(31, 41, 71, 100%);
  box-shadow: 3px 3px 3px rgb(31, 41, 71, 100%);
  font-size: 1.1em;
}
#sectionLinks button img {
  width: 32px;
  margin-right: 0.25em;
  padding: 2px;
  border-radius: 0.25em;
  background-color: white;
  box-shadow: 1px 1px 2px rgb(31, 41, 71, 100%);
}
#sectionLinks button:hover {
  border: none;
  color: cornflowerblue;
  background-color: rgb(31, 41, 71, 0.75);
  box-shadow: inset 3px 3px 2px rgb(31, 41, 71, 100%);
  text-shadow: 0px 0px 2px darkmagenta;
  cursor: pointer;
}
#sectionLinks button:hover img {
  width: 32px;
  padding: 2px;
  border-radius: 0.25em;
  background-color: white;
  box-shadow: 0px 0px 2px darkmagenta;
}
</style>
